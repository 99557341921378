
import { defineComponent, ref, onMounted } from "vue";
import ApiService from "@/core/services/ApiService";
import Notice from "@/components/Notice.vue";
import { statusColor } from "@/core/services/HelpersFun";
import { useRoute } from "vue-router";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
// import UserAvatar from "@/components/UserAvatar.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "multiple-messages",
  components: {
    Notice,
    // UserAvatar,
  },
  props: {
    widgetClasses: String,
  },
  setup() {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const loading = ref(true);
    const list = ref();
    const pagination = ref();
    const screen_shot = ref(false);
    const screen_path = ref('');
    

    const page = ref(1);
    const filter = ref("latest_sends");
    const store = useStore();
    const route = useRoute();
    const getItems = () => {
      loading.value = true;
      let endPointUrl =
        route.name === "api"
          ? `message/notifications-log?sort=${filter.value}&with_user=true`
          : `admin/message/notifications-log?sort=${filter.value}&with_user=true`;

      let params = { page: page.value };
      ApiService.setHeader();
      ApiService.query(endPointUrl, { params })
        .then(({ data }) => {
          list.value = data.data.notifications.data;
          pagination.value = data.data.notifications.pagination;
        })
        .catch(({ response }) => {
          store.commit(Mutations.SET_ERROR, response.data);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    onMounted(() => {
      getItems();
    });

    const handleSizeChange = (val: number) => {
      return;
    };
    const handleCurrentChange = (val: number) => {
      page.value = val;
      getItems();
    };

    onMounted(() => {
      if (route.name === "apiManagement") {
        setCurrentPageBreadcrumbs(
          translate("Messages sent with the web service"),
          ["management"]
        );
      }
    });

    return {
      list,
      loading,
      pagination,
      handleCurrentChange,
      handleSizeChange,
      page,
      getItems,
      statusColor,
      translate,
      filter,
      screen_shot,
      screen_path,
    };
  },
});
