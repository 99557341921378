
import { defineComponent, ref, onMounted, watch } from "vue";
import ClipboardJS from "clipboard";
import { ElNotification } from "element-plus";
import ApiService from "@/core/services/ApiService";
import { hideModal } from "@/core/helpers/dom";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import Notice from "@/components/Notice.vue";
import { useI18n } from "vue-i18n";
import { AllBots } from "@/store/pinia/AllBots";
import { storeToRefs } from "pinia";

interface ApiInterface {
  name: string;
  domain : string;
  expired_at: string;
}

export default defineComponent({
  name: "share-api-crud-modal",

  emits: ["refreshApiList"],
  components: {
    Notice,
  },
  props: {
    open: {
      type: Number,
      default: 0,
    },
    showApi: {
      type: Object,
      default: () => ({}),
    },
    forBotId: {
      type: String,
      default: undefined,
    },
    clickedBot: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };
    const store = useStore();

    const errorsBag = ref({});
    const level = ref("first");
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const botId = ref();
    const copyButtonRef = ref<null | HTMLElement>(null);
    const inputRef = ref<null | HTMLElement>(null);
    const formRef = ref<null | HTMLFormElement>(null);
    const tokenString = ref("");
    const targetData = ref<ApiInterface>({
      name: "",
      domain : "",
      expired_at: "",
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: translate("Please enter the name of the service"),
          trigger: "blur",
        },
      ],
    });

    const AllBotsHolder = AllBots();
    const { botList } = storeToRefs(AllBotsHolder);

    onMounted(() => {
      const clipboard = new ClipboardJS(copyButtonRef.value as Element);

      clipboard.on("success", function (e) {
        const buttonCaption = copyButtonRef.value?.innerHTML;

        //Add bg color
        inputRef.value?.classList.add("bg-success");
        inputRef.value?.classList.add("text-inverse-success");

        if (copyButtonRef.value) {
          copyButtonRef.value.innerHTML = translate("copied");
        }

        setTimeout(function () {
          if (copyButtonRef.value && buttonCaption) {
            copyButtonRef.value.innerHTML = buttonCaption;
          }

          // Remove bgcolor
          inputRef.value?.classList.remove("bg-success");
          inputRef.value?.classList.remove("text-inverse-success");
        }, 3000); // 3seconds

        e.clearSelection();
      });
    });

    const submit = () => {
      if (!formRef.value) return;

      errorsBag.value = {};
      formRef.value.validate((valid) => {
        if (valid) {
          if (!submitButtonRef.value) {
            return;
          }

          //Disable button
          submitButtonRef.value.disabled = true;
          // Activate indicator
          submitButtonRef.value.setAttribute("data-kt-indicator", "on");

          let data = {
            bot_id: botId.value,
            name: targetData.value.name,
            domain : targetData.value.domain,
            expired_at: targetData.value.expired_at,
          };

         
          ApiService.post("token/create", data)
            .then(({ data }) => {
              level.value = "second";
              tokenString.value = data.data.token.token;
              emit("refreshApiList");

              // clear modal
               targetData.value = {
                name: "",
                domain :"",
                expired_at: "",
              };
              botId.value = ""
            })
            .catch((e) => {
              store.commit(Mutations.SET_ERROR, e.response?.data);

              if (e.response!.status === 403) {
                ElNotification({
                  title: translate("error"),
                  message: translate(
                    "The purchased package does not have the ability to use api"
                  ),
                  type: "error",
                });
                return;
              }
              if (e.response!.status === 400) {
                errorsBag.value = e.response.data.data;
              }
              ElNotification({
                title: translate("error"),
                message: translate("Error creating api"),
                type: "error",
              });
            })
            .finally(() => {
              if (submitButtonRef.value) {
                submitButtonRef.value.disabled = false;
                submitButtonRef.value?.removeAttribute("data-kt-indicator");
              }
            });
        }
      });
    };

    watch(
      () => props.forBotId,
      () => {
        botId.value = props.forBotId;
      }
    );
    watch(
      () => props.showApi,
      (data) => {
        if (!data) {
          level.value = "first";
          return;
        }

        data = JSON.parse(JSON.stringify(data));
        if (!data || !data.token) {
          level.value = "first";
          return;
        }

        tokenString.value = data.token;
        level.value = "second";
      }
    );

    return {
      copyButtonRef,
      inputRef,
      rules,
      submitButtonRef,
      submit,
      level,
      formRef,
      targetData,
      tokenString,
      botList,
      botId,
      errorsBag,
      hideModal,
      translate,
    };
  },
});
