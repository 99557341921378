
import { defineComponent, onMounted, ref, watch } from "vue";
import { ElNotification } from "element-plus";
import ApiCrudModal from "@/views/pages/services/api/component/ApiCrudModal.vue";
import ApiService from "@/core/services/ApiService";
import BulkMessagesAndApiChart from "@/components/BulkMessagesAndApiChart.vue";
import Notifications from "@/views/pages/services/api/component/Notifications.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "kt-table-api-list",
  props: {
    clickedBot: {
      type: String,
      default: "",
    },
  },
  components: {
    ApiCrudModal,
    BulkMessagesAndApiChart,
    Notifications,
  },
  setup(props) {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const selectedBotByUser = ref();
    watch(props, () => {
      selectedBotByUser.value = props.clickedBot;
    });
    const loading = ref(true);
    const list = ref();
    const pagination = ref();
    const page = ref(1);
    const showApi = ref();
    const modalOpened = ref(0);
    const svg = `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
      `;

    const getItems = () => {
      ApiService.query("token", { params: { page: page.value } })
        .then(({ data }) => {
          loading.value = false;
          list.value = data.data.tokens.data;
          pagination.value = data.data.tokens.pagination;
        })
        .catch(() => {
          // ElNotification({
          //   title: "خطا",
          //   message: "خطا در بارگزاری لیست api ها",
          //   type: "error",
          // });
        });
    };

    onMounted(() => {
      setTimeout(getItems, Math.floor(Math.random() * (3 - 1 + 1) + 1) * 1000);
      setCurrentPageBreadcrumbs(
        translate("Web service (connection to the site)"),
        []
      );
    });

    const handleSizeChange = (val: number) => {
      return;
    };
    const handleCurrentChange = (val: number) => {
      page.value = val;
      getItems();
    };

    const deleteRecord = (id) => {
      if (
        !confirm(
          translate(
            "By removing the api the programs that use this service will be disrupted Are you sure you want to delete this api?"
          )
        )
      )
        return;

      ApiService.post(`token/${id}/delete`, [])
        .then(getItems)
        .catch(() => {
          ElNotification({
            title: translate("error"),
            message: translate("Error deleting api!"),
            type: "error",
          });
        });
    };

    const example_json = {
      messages: [
        {
          message:
            "your message type here 1" /*متن پیام خود را اینجا وارد کنید*/,
          message_type: "message",
          attachment_file: "",
          with_file: false,
        },
        {
          message: "your message type here 1", //
          message_type: "image",
          attachment_file: "dl1/AMFGlno94242qeBfyVuynXaF2QT9uxxZoc1RudVl.jpg",
          with_file: false,
        },
      ],
      recipients: [],
      bot_id: "wa-6992125",
      platforms: ["telegram", "bale", "eitaa"],
      send_schedule: "2023-10-26",
      setting: {
        start_clock: "",
        finish_clock: "",
        send_count: 3,
        break_time: 2,
        messages_count_per_day: 70,
      },
      with_country_code: true,
      country_code: "+98",
    };

    const handleCopy = (value) => {
      navigator.clipboard
        .writeText(value)
        .then(() => {
          // می‌توانید در اینجا اقدامات دیگری انجام دهید
        })
        .catch((error) => {
          // console.error("Unable to copy text to clipboard:", error);
        });
    };

    return {
      list,
      loading,
      svg,
      pagination,
      handleCurrentChange,
      handleSizeChange,
      deleteRecord,
      getItems,
      showApi,
      modalOpened,
      selectedBotByUser,
      translate,
      example_json,
      handleCopy,
    };
  },
});
